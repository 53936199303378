<template>
  <div>
    <Dashboard />
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import Dashboard from "@/views/dashboard/index.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    Dashboard,
  },
};
</script>

<style></style>
